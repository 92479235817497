import Vue from "vue";
import App from "./App.vue";
import AsyncComputed from "vue-async-computed";
import VueRouter from "vue-router";
import Callback from "./components/Callback.vue";
import Auth, { authRedirectGuard } from "./auth";
import Login from "./components/Login.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGitlab } from "@fortawesome/free-brands-svg-icons";
import Axios from "axios";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import BootstrapVue from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

library.add(faWeightHanging);
library.add(faGitlab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;

Vue.use(BootstrapVue);
Vue.use(Auth);
Vue.use(VueRouter);
Vue.use(AsyncComputed);

const routes = [
    { path: "/login", name: "login", component: Login },
    { path: "/callback", component: Callback },
    { path: "/:milestoneType?/:resourceId?", name: "dashboard", component: App, meta: { requiresAuth: true } },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach(authRedirectGuard());

new Vue({
    router,
}).$mount("#app-container");
