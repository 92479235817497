<script>
export default {
  name: "Callback",
  render() {
    return ""
  },
  async beforeMount() {
    await this.$auth.handleAuthentication()
    console.log('Token', this.$auth.accessToken)
    await this.$router.push({
      name: 'dashboard'
    })
  }
}
</script>
