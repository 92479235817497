<template>
  <div class="login">
    <div v-if="!$auth.isAuthenticated()" class="login-button">
      <button @click="$auth.login()">
        <font-awesome-icon size="2x" :icon="['fab', 'gitlab']" class="button-icon"/>
        Authenticate with Gitlab
      </button>
    </div>
    <div v-else>
      <button @click="$auth.logout()">Logout</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Login",
})
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.button-icon {
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -0.125em;  /* https://stackoverflow.com/questions/17478710/vertical-alignment-of-text-and-icon-in-button#comment33623667_17479613 */
}

button {
  font-size: 18px;
  padding: 32px;
}
</style>
