<template>
  <div id="issue-viewer">
    <b-form-select :options="milestoneTypes" v-model='selectedMilestoneType'
                   @change="milestoneTypeChanged"/>
    <MilestoneSelector
        :type="selectedMilestoneType"
        :resources="filterResource"
        @resource-change="resourceChange"
        @milestone-change="milestoneChange">
    </MilestoneSelector>
    <IssueList v-if="issues" :issues="issues" :labels="labels" :loading="loading"/>
  </div>
</template>

<script>
import Vue from "vue";
import MilestoneSelector from "./MilestoneSelector";
import IssueList from "./IssueList";
import {GitlabClient} from '@/lib/gitlab-client';

import config from "@/config";

const axios = require('axios').default;

export default Vue.extend({
  name: 'App',
  components: {
    MilestoneSelector,
    IssueList,
  },
  data: () => {
    return {
      milestoneTypes: [
        {value: 'project', text: "Projects"},
        {value: 'group', text: 'Groups'}
      ],

      serverUrl: config.gitlab_url,

      selectedMilestoneType: 'project',
      selectedResource: undefined,
      selectedMilestone: undefined,

      loading: false,
    }
  },
  methods: {
    milestoneTypeChanged() {
      this.selectedResource = undefined;
      this.selectedMilestone = undefined;
      this.updateRoute();
    },
    resourceChange(event) {
      console.log('Resource change')
      this.selectedResource = event
      this.updateRoute()
    },
    milestoneChange(event) {
      console.log('Milestone change')
      this.selectedMilestone = event
      this.updateRoute()
    },
    updateRoute() {
      this.$router.replace({
        name: 'dashboard',
        params: {
          milestoneType: this.selectedMilestoneType,
          resourceId: this.selectedResource
        }
      }, () => {}, () => {})
    }
  },
  mounted() {
    if (this.$route.params.milestoneType) {
      this.selectedMilestoneType = this.$route.params.milestoneType
    }
    if (this.$route.params.resourceId) {
      this.selectedResource = this.$route.params.resourceId
    }
  },
  computed: {
    filterResource() {
      if (this.selectedMilestoneType === "project") {
        return this.projects;
      } else if (this.selectedMilestoneType === "group") {
        return this.groups;
      }
      return [];
    },
    http() {
      return axios.create({
        baseURL: `${this.serverUrl}/api/v4`,
        timeout: 5000,
        headers: {
          'Authorization': `Bearer ${this.$auth.accessToken}`
        }
      });
    },
    gitlabClient() {
      if (!this.serverUrl || !this.$auth.isAuthenticated()) {
        return undefined;
      }
      return new GitlabClient(this.serverUrl, this.$auth.accessToken);
    }
  },
  asyncComputed: {
    projects() {
      console.log('fetch Projects')
      this.loading = true
      if (!this.gitlabClient) {
        return []
      }
      return this.gitlabClient.projects().finally(() => this.loading = false);
    },
    groups() {
      console.log('fetch groups')
      this.loading = true;
      if (!this.serverUrl) {
        return []
      }
      return this.gitlabClient.groups().finally(() => this.loading = false);
    },
    issues() {
      if (!this.selectedResource || !this.selectedMilestone) {
        return [];
      }

      console.log(`Fetching ${this.selectedResource} milestone ${this.selectedMilestone}`)

      this.loading = true
      return this.gitlabClient.issues(this.selectedResource, this.selectedMilestoneType, undefined, this.selectedMilestone).finally(() => this.loading = false)
    },
    labels() {
      if (!this.selectedResource || !this.gitlabClient) {
        return []
      }
      return this.gitlabClient.labels(this.selectedResource, this.selectedMilestoneType);
    }
  }
})
</script>

<style scoped>
#issue-viewer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 60px auto 0;
  width: 960px;
}
</style>
