import Vue from "vue";
import axios from "axios";

import config from "@/config";

let auth = new Vue({
  computed: {
    token: {
      get: function() {
        return localStorage.getItem("id_token");
      },
      set: function(id_token: string) {
        localStorage.setItem("id_token", id_token);
      },
    },
    accessToken: {
      get: function() {
        return localStorage.getItem("access_token");
      },
      set: function(accessToken: string) {
        localStorage.setItem("access_token", accessToken);
      },
    },
    expiresAt: {
      get: function() {
        return localStorage.getItem("expires_at");
      },
      set: function(expiresIn: number) {
        if (expiresIn === null) {
          localStorage.setItem("expires_at", "");
        } else {
          let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
          localStorage.setItem("expires_at", expiresAt);
        }
      },
    },
    user: {
      get: function() {
        return JSON.parse(localStorage.getItem("user") || "{}");
      },
      set: function(user: any) {
        localStorage.setItem("user", JSON.stringify(user));
      },
    },
  },
  methods: {
    login() {
      // TODO: Redirect to login page
      console.log("[login()]");

      const clientId = "6c3da65588cf7eeff6722461fbb1688be9a8d098a51e6497cc1b8303835a0af0";
      const redirectUri = window.location.origin + '/callback';
      const scopes = "read_api";
      const responseType = "code";
      window.location.assign(
        `${config.gitlab_url}/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scopes}`
      );
    },
    logout() {
      console.log("[logout()]");
      // eslint-disable-next-line no-unused-vars
      return new Promise<void>((resolve, reject) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("user");
        // TODO: Call logout
        resolve();
      });
    },
    isAuthenticated() {
      return this.accessToken && (!this.expiresAt || new Date().getTime() < this.expiresAt);
    },
    handleAuthentication() {
      const params = (new URL(document.location.toString())).searchParams
      const code = params.get("code") as string;

      console.log("[handleAuthentication]", code);

      const pkceParams = {
        client_id: '6c3da65588cf7eeff6722461fbb1688be9a8d098a51e6497cc1b8303835a0af0',
        client_secret: 'e87d33eed7bea3a3715e56fc878865a6f9d073d1506a24929e58ca525bd302e8',
        code: code,
        grant_type: 'authorization_code',
        redirect_uri: window.location.origin + '/callback',
      }

      const queryString = Object.entries(pkceParams).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');
      return fetch(`${config.gitlab_url}/oauth/token?` + new URLSearchParams(queryString),
      {
        method: 'POST',
      })
      .then(response => response.json())
      .then(data => {
        console.log('Token response body', data);
        const tokenEndpoint = `${config.gitlab_url}/oauth/token/info`;

        const tokenType = data.token_type;
        const expiresIn = data.expires_in;
        const accessToken = data.access_token;

        this.accessToken = accessToken;
        this.expiresAt = expiresIn;
        this.token = "";

        // TODO: This might work, once this Gitlab issue is fixed: https://gitlab.com/gitlab-org/gitlab/-/issues/364680
        const fetchTokenInfo = false;
        if (fetchTokenInfo) {
          return new Promise<void>((resolve, reject) => {
            axios
              .get(tokenEndpoint, {
                headers: {
                  Authorization: `${tokenType} ${accessToken}`,
                },
              })
              .then((response) => {
                console.log("Token info endpoint response", response);
                this.user = response.data["resource_owner_id"];
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          });
        }
        return axios.get(
          `${config.gitlab_url}/api/v4/user`, {
          headers: {
            Authorization: `${tokenType} ${accessToken}`,
          },
        }).then((response) => {
            console.log("Token info endpoint response", response);
            this.user = response.data;
        })
      })
    },
  },
});

export function authRedirectGuard() {
  return async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem("access_token")) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  };
}

export default {
  install: function(Vue) {
    Vue.prototype.$auth = auth;
  },
};
