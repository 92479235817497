<template>
  <div id="issue-list">
    <b-overlay :show="loading">
      <ul v-for="issue in sortedIssues" :key="issue.id">
        <li>
          <a :href="issue.web_url" class="issue-link" target="_blank">
            {{issueIdLink(issue)}}
          </a>
          <span class="issue-text" :data-state="issue.state">{{ issue.title }}</span>
          <span class="weight" v-if="issue.weight">
              <font-awesome-icon :icon="['fas', 'weight-hanging']"/>
              {{ issue.weight }}
            </span>

          <IssueLabel class="issue-label" v-for="label in issue.labels" :key="label" :text="label" :textColor="textColor(label)" :bgColor="bgColor(label)">
            {{ label }}
          </IssueLabel>
        </li>
      </ul>
    </b-overlay>
  </div>
</template>

<script>
import IssueLabel from "./IssueLabel"

export default {
  name: "IssueList",
  props: ["issues", "labels", "loading"],
  components: {IssueLabel},
  computed: {
    sortedIssues() {
      return [...this.issues].sort((i1, i2) => i1.references.relative < i2.references.relative ? -1 : 1);
    }
  },
  methods: {
    labelStyle(label) {
      let filteredLabels = this.labels.filter(l => l.name === label) || [];
      if (filteredLabels.length === 0) {
        return {};
      }
      let bgColor = filteredLabels[0]['color'] || 'cyan'
      let textColor = filteredLabels[0]['text_color'] || 'white'
      return {
        color: textColor,
        backgroundColor: bgColor
      }
    },
    bgColor(label) {
      let filteredLabels = this.labels.filter(l => l.name === label) || [];
      if (filteredLabels.length === 0) {
        return 'cyan';
      }
      return filteredLabels[0]['color'] || 'cyan'
    },
    textColor(label) {
      let filteredLabels = this.labels.filter(l => l.name === label) || [];
      if (filteredLabels.length === 0) {
        return 'white';
      }
      return filteredLabels[0]['text_color'] || 'white'
    },
    issueIdLink(issue) {
      const parts = issue.references.relative.toString().split('/')
      return parts[parts.length - 1]
    }
  },
}
</script>

<style scoped>
#issue-list {
  width: 960px;
  margin: 16px auto;
  padding: 16px;

  background-color: #f7f7f7;
  border-radius: 8px;
}

.issue-link {
  display: inline-block;
  width: 10em;
  font-size: 70%;
  text-align: end;
  margin-right: 1ex;
}

.issue-text[data-state="closed"] {
  text-decoration: line-through;
}

.weight {
  color: darkgrey;
  user-select: none;
  margin-left: 1ex;
}

ul {
  text-align: left;
}

#issue-list a {
  user-select: none;
}

ul li {
  list-style: none;
}

.issue-label {
  float: right;
}

.issue-label + .issue-label {
  margin-right: 4px;
}
</style>
