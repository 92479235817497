<template>
  <header>
    <h1>
      Planning Poker Prepper
    </h1>
    <div class="auth-actions">
    <div v-if="$auth.isAuthenticated()">
      Authenticated as {{$auth.user.name }} ({{ $auth.user.username }})
      <a href="#" @click="logout()">Logout</a>
    </div>
    <div v-else>
      Not logged in
    </div>
    </div>
  </header>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "NavHeader",
  methods: {
    logout() {
      this.$auth.logout().then(() => this.$router.push({name: "login"}));
    }
  }
})
</script>

<style scoped>
header {
  width: 100%;
  margin: 0;
  padding: 32px 0;
  background-color: #8794a0;
}

.auth-actions {
  float: right
}
</style>
