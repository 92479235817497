<template>
  <div id="selector-form">
    <label for="filter-text">Filter</label>
    <input id="filter-text" type="search" v-model="filterText">

    <label for="resource-select">{{ type }}</label>
    <select id="resource-select" @change="resourceChange">
      <option v-for="r in filteredResources" :key="r.id" :value="r.id" :selected="r.id === selectedResourceId">
        {{ displayName(r) }}
      </option>
    </select>
    <label for="milestone-select">{{ type }} Milestone</label>
    <select id="milestone-select" @change="milestoneChange">
      <option value="undefined">--- select ---</option>
      <option v-for="m in iterations" :key="m.id" :value="m.id">
        {{milestoneTitle(m)}}
      </option>
    </select>
  </div>
</template>

<script>
import Vue from "vue";
import {GitlabClient} from "@/lib/gitlab-client"

import config from "@/config";

export default Vue.extend({
  name: "MilestoneSelector",
  props: ["resources", "type"],
  data: () => {
    return {
      selectedResourceId: undefined,
      serverUrl: config.gitlab_url,
      filterText: "",
    }
  },
  beforeMount() {
    if (this.$route.params.resourceId) {
      this.selectedResourceId = parseInt(this.$route.params.resourceId);
    }
  },
  methods: {
    resourceChange(ev) {
      console.log('Selected resource', ev.target.value);
      this.selectedResourceId = ev.target.value;
      this.$emit('resource-change', ev.target.value);
    },
    milestoneChange(ev) {
      console.log('Selected milestone', ev.target.value);
      this.$emit('milestone-change', ev.target.value);
    },
    displayName(r) {
      if (this.type === "project") {
        return r.name_with_namespace;
      } else if (this.type === "group") {
        return r.full_name;
      }
      return "???";
    },
    milestoneTitle(m) {
      if (m.title) {
        return `${m.title} (${m.start_date} → ${m.due_date})`
      } else {
        return `${m.start_date} → ${m.due_date}`
      }
    }
  },
  mounted() {
    console.log('Selected resource', this.selectedResourceId)
  },
  computed: {
    gitlabClient() {
      console.log('Get client', this.serverUrl, this.$auth.accessToken)
      if (!this.serverUrl || !this.$auth.accessToken) {
        return undefined;
      }
      return new GitlabClient(this.serverUrl, this.$auth.accessToken);
    },
    filteredResources() {
      if (this.resources === undefined || this.resources === null) {
        return [];
      }
      const filter = this.filterText.toLowerCase();
      return this.resources
          .filter(r => this.displayName(r).toLowerCase().includes(filter));
    }
  },
  asyncComputed: {
    async milestones() {
      if (!this.gitlabClient || !this.selectedResourceId) {
        return;
      }
      console.log("Fetching milestones", this.selectedResourceId)
      return this.gitlabClient.milestones(this.selectedResourceId, this.type);
    },
    async iterations() {
      if (!this.gitlabClient || !this.selectedResourceId) {
        return;
      }
      console.log("Fetching iterations", this.selectedResourceId)
      return this.gitlabClient.iterations(this.selectedResourceId, this.type);
    }
  }
})
</script>

<style scoped>
#selector-form {
  width: 960px;
  margin: 16px auto;
  padding: 16px;

  background-color: #f0f0f0;
  border-radius: 8px;

  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: baseline;
}

#selector-form label {
  grid-column: 1 / 2;
  line-height: 34px;
  text-align: right;
  text-transform: capitalize;
}

#selector-form input,
#selector-form button,
#selector-form select {
  grid-column: 2 / 3;
}

.span-cols {
  grid-column: 1 / 3;
}
</style>
