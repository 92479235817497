<template>
  <span
    v-if="isScoped"
    class="label"
    :style="cssVars">
    <span class="scope" v-text="scope"></span>
    <span class="tag" v-text="label"></span>
  </span>
  <span
    v-else
    class="label"
    :style="cssVars">
    <span class="simple" v-text="label"></span>
  </span>
</template>

<script>
export default {
    name: "IssueLabel",
    props: ["text", "textColor", "bgColor"],
    computed: {
      isScoped() {
        return this.text.includes("::");
      },
      scope() {
        if (this.isScoped) {
          return this.text.split("::")[0];
        } else {
          return "";
        }
      },
      label() {
        if (this.isScoped) {
          return this.text.split("::")[1];
        } else {
          return this.text;
        }
      },
      cssVars() {
        return {
          "--border-radius": "8px",
          "--text-color": this.textColor,
          "--bg-color": this.bgColor,
        }
      }
    }
}
</script>

<style>
.label {
  display: inline-flex;
  user-select: none;
  font-size: 80%;
}

.label span {
  flex: auto;
  padding: 0px 8px;
  align-content: center;
}

.label .simple {
  border: 1px solid var(--bg-color);
  border-radius: var(--border-radius);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.label .scope {
  border-bottom-left-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border-top: 1px solid var(--bg-color);
  border-left: 1px solid var(--bg-color);
  border-bottom: 1px solid var(--bg-color);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.label .tag {
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-top: 1px solid var(--bg-color);
  border-right: 1px solid var(--bg-color);
  border-bottom: 1px solid var(--bg-color);
  color: var(--bg-color);
  background-color: white;
}
</style>
