import Axios, { AxiosInstance } from "axios";

export interface NamespaceInfoSchemaDefault {
  id: number;
  name: string;
  path: string;
  kind: string;
  full_path: string;
}

export interface ProjectSchemaDefault {
  id: number;
  name: string;
  name_with_namespace: string;
  path: string;
  path_with_namespace: string;
  namespace: NamespaceInfoSchemaDefault;
  ssh_url_to_repo: string;
  http_url_to_repo: string;
  archived: boolean;
}

export interface GroupSchemaDefault {
  id: number;
  name: string;
  path: string;
  full_name: string;
  full_path: string;
  parent_id: number;
  visibility: string;
  avatar_url: string;
  web_url: string;
}

export interface BaseMilestoneSchema {
  id: number;
  iid: number;
  title: string;
  description: string;
  due_date: string;
  start_date: string;
  state: string;
  updated_at: string;
  created_at: string;
  expired: boolean;
}

export interface ProjectMilestoneSchema extends BaseMilestoneSchema {
  project_id: number;
}

export interface GroupMilestoneSchema extends BaseMilestoneSchema {
  group_id: number;
}

type ResourceType = "project" | "group";

export class GitlabClient {
  private http: AxiosInstance;

  constructor(serverUrl: string, accessToken: string) {
    this.http = Axios.create({
      baseURL: `${serverUrl}/api/v4`,
      timeout: 5000,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private async fetchPages(uri: string, params: Record<string, any> = {}) {
    let page = 1;
    let total_pages = 1;
    let result: any[] = [];
    do {
      let full_params = {
        ...params,
        per_page: 100,
        page,
      };
      let list = await this.http.get(uri, { params: full_params }).then((response) => {
        total_pages = parseInt(response.headers["x-total-pages"]);
        return response.data;
      });
      result.push(...list);
      ++page;
    } while (page <= total_pages);
    return result;
  }

  async projects(): Promise<ProjectSchemaDefault[]> {
    let result: ProjectSchemaDefault[] = await this.fetchPages("/projects", { simple: true });
    return result.sort((p1, p2) =>
      p1.name_with_namespace.toLowerCase().localeCompare(p2.name_with_namespace.toLowerCase())
    );
  }

  async groups(): Promise<GroupSchemaDefault[]> {
    let result: GroupSchemaDefault[] = await this.fetchPages("/groups", { simple: true });
    return result.sort((p1, p2) => p1.full_name.toLowerCase().localeCompare(p2.full_name.toLowerCase()));
  }

  async milestones(resourceId: string, type: ResourceType): Promise<BaseMilestoneSchema[]> {
    let params = {
      include_parent_milestones: true,
    };
    return this.http.get(`/${type}s/${resourceId}/milestones`, { params }).then((response) => response.data);
  }

  async iterations(resourceId: string, type: ResourceType): Promise<BaseMilestoneSchema[]> {
    let params = {
      include_parent_milestones: true,
    };
    return this.http.get(`/${type}s/${resourceId}/iterations`, { params }).then((response) => response.data);
  }
  
  async issues(resourceId: string, type: ResourceType, milestoneId?: string, iterationId?: string): Promise<any[]> {
    let params = {
      milestone: milestoneId,
      iteration_id: iterationId,
    };
    return this.fetchPages(`/${type}s/${resourceId}/issues`, params);
  }

  async labels(resourceId: string, type: ResourceType) {
    return this.fetchPages(`/${type}s/${resourceId}/labels`);
  }
}
